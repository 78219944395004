import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { t } from '@/utility/localization';

import { COMMUNITY_CATEGORIES_ID } from '@/features/CmOnboarding/components-onboarding/SelectCommCategory';

import {
  CONTENT_TEMPLATE_ID,
  PRODUCT_TYPE
} from '@/contexts/LibrarySetupContext/constants';

export const [TAB_COURSES, TAB_RESOURCES] = ['courses', 'resources'];

export const VIDEO_PLATFORMS = {
  youtube: 'YouTube',
  vimeo: 'Vimeo'
};

export const STATUS_PUBLISHED = 'Published';
export const STATUS_DRAFT = 'Draft';
export const STATUS_UNPUBLISHED = 'Unpublished';

export const THUMBNAIL = {
  video: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-video.png`,
  external_link: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-link.png`,
  file: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-files.png`,
  audio: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-audio.png`,
  image: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/feb12/thumbnail-image.png`
};

export const EMPTY_LANDING_PAGE = {
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/EmptyLibraryPage.png`,
    width: 660,
    height: 220
  }
};
export const courseEmptyStateImage = {
  mobileImgProps: {
    src: `${STATIC_ASSET_BASE_URL}/nasIO/library/png/course-empty-image.png`,
    width: 399,
    height: 280
  }
};

export const PRODUCT_CONTENT_TAB = 'content';
export const PRODUCT_AUDIENCE_TAB = 'audience';

export const getHeaderTabs = () => [
  {
    id: PRODUCT_CONTENT_TAB,
    label: t('content')
  },
  {
    id: PRODUCT_AUDIENCE_TAB,
    label: t('audience')
  }
];

export const folderTableDataFields = () => [
  {
    label: t('name')
  },
  {
    label: t('type'),
    customWidth: '140px'
  },
  {
    label: t('sales'),
    width: '16px'
  },
  {
    customWidth: '84px'
  }
];

// item types
export const FOLDER_ITEM_TYPE_SECTION = 'section';
export const FOLDER_ITEM_TYPE_FILE = 'file';
export const FOLDER_ITEM_TYPE_VIDEO = 'video';
export const FOLDER_ITEM_TYPE_AUDIO = 'audio';
export const FOLDER_ITEM_TYPE_LINK = 'external_link';
export const FOLDER_ITEM_TYPE_IMAGE = 'image';

export const getDiscountAnnouncementContentPageModalProps = () => ({
  title: t('create-discount-for-paid-content'),
  description: t(
    'click-on-the-to-create-a-new-discount-and-get-more-sales'
  ),
  imageProps: {
    alt: 'content-page',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/portal/discount/content.png`,
      width: 500,
      height: 250
    }
  }
});

export const [SESSION_1_ON_1_ONLINE, SESSION_1_ON_1_IN_PERSON] = [
  'online',
  'inPerson'
];

export const [PRODUCT_ACCESS_FREE, PRODUCT_ACCESS_PAID] = ['free', 'paid'];

export const BOOKINGS_TYPE = {
  ALL: 'ALL',
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
  CANCELLED: 'CANCELLED'
};

export const getAudienceDataFields = (isPaid) => {
  let fields = [
    {
      label: t('name'),
      customWidth: '200px'
    },
    {
      label: t('email'),
      customWidth: '100px'
    }
  ];

  if (isPaid) {
    fields = [
      ...fields,
      {
        label: t('status'),
        customWidth: '70px'
      },
      {
        label: t('purchase-date'),
        customWidth: '70px'
      },
      {
        label: t('price-paid'),
        customWidth: '70px'
      }
    ];
  } else {
    fields = [
      ...fields,
      {
        label: t('access-date'),
        customWidth: '70px'
      }
    ];
  }

  return fields;
};

export default () => {
  const PRODUCT_ANNOUNCEMENT_MODAL_PROPS = {
    title: "Say Hello to 'Products'!",
    description:
      "It's the same 'Content' you love, with a twist. Explore our new 1-1 session feature.",
    imageProps: {
      alt: '1 dollar promotion',
      mobileImgProps: {
        src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/firstdollar.png`,
        width: 500,
        height: 250
      }
    }
  };

  const PRODUCT_TEMPLATES_PROPS = {
    DEFAULT: [
      {
        id: CONTENT_TEMPLATE_ID.NO_CATEGORY_PRODUCT,
        label: t('digital-product'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.NO_CATEGORY_COURSES,
        label: t('course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.NO_CATEGORY_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ],
    [COMMUNITY_CATEGORIES_ID.FINANCE]: [
      {
        id: CONTENT_TEMPLATE_ID.FINANCE_PRODUCT,
        label: t('finance-pdf'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.FINANCE_COURSES,
        label: t('finance-course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.FINANCE_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ],
    [COMMUNITY_CATEGORIES_ID.PERSONAL_DEVELOPMENT]: [
      {
        id: CONTENT_TEMPLATE_ID.CAREER_PRODUCT,
        label: t('digital-product'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.CAREER_COURSES,
        label: t('course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.CAREER_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ],
    [COMMUNITY_CATEGORIES_ID.FITNESS]: [
      {
        id: CONTENT_TEMPLATE_ID.FITNESS_PRODUCT,
        label: t('fitness-guide'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.FITNESS_COURSES,
        label: t('fitness-course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.FITNESS_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ],
    [COMMUNITY_CATEGORIES_ID.NUTRITION]: [
      {
        id: CONTENT_TEMPLATE_ID.NUTRITION_PRODUCT,
        label: t('nutrition-plan'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.NUTRITION_COURSES,
        label: t('nutrition-course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.NUTRITION_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ],
    [COMMUNITY_CATEGORIES_ID.SPIRITUALITY_RELIGION]: [
      {
        id: CONTENT_TEMPLATE_ID.RELIGION_PRODUCT,
        label: t('digital-product'),
        icon: 'book-closed',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.RELIGION_COURSES,
        label: t('course'),
        icon: 'video-courses',
        backgroundFill: 'pink-light-3',
        iconFill: '#CD1D8D',
        type: PRODUCT_TYPE.DIGITAL_PRODUCT
      },
      {
        id: CONTENT_TEMPLATE_ID.RELIGION_SESSION,
        label: t('1-1-session'),
        icon: '1-1-session',
        backgroundFill: 'success-light-3',
        iconFill: '#18774C',
        type: PRODUCT_TYPE.SESSION
      }
    ]
  };

  const PRODUCT_TYPES_PROPS = [
    {
      id: 'DIGITAL_PRODUCT',
      title: t('digital-product'),
      description: t('share-an-e-book-guide-or-more'),
      icon: 'file-01',
      iconFill: '#CD1D8D',
      type: 'DIGITAL_PRODUCT',
      iconBg: 'pink-light-3'
    },
    {
      id: 'SESSION',
      title: t('1-1-session'),
      description: t('host-an-exclusive-session'),
      icon: '1-1-session',
      iconFill: '#18774C',
      iconBg: 'success-light-3',
      showNewTag: true
    }
  ];

  const PRODUCT_TYPES_LABEL = {
    SESSION: t('1-1-session'),
    DIGITAL_PRODUCT: t('digital-product')
  };

  return {
    PRODUCT_ANNOUNCEMENT_MODAL_PROPS,
    PRODUCT_TEMPLATES_PROPS,
    PRODUCT_TYPES_PROPS,
    PRODUCT_TYPES_LABEL
  };
};
